import React from 'react'

const ErrorBoundary = () => {
  return (
    <div className='flex flex-col gap-2 w-full h-screen text-center items-center justify-center text-white bg-black'>
      Opps! Something went wrong. Please go back
    </div>
  )
}

export default ErrorBoundary;
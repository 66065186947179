import React from 'react';

const Learn = () => {
  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="flex flex-col w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full h-fit max-md:px-[10px]">
        {/* Title */}
        <div className='flex m-2 text-[#31bfff] h-[80px] bg-white rounded-xl p-2 justify-center items-center text-center text-4xl max-md:text-3xl max-sm:text-2xl font-bold'>Learning and Unlearning        </div>
      </div>
      {/* Lecture Notes */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="bg-white h-fit py-4 w-full">
          <div className='text-[25px] text-center my-2 max-md:text-[20px] font-bold text-[#ffca05]'>Lecture Notes</div>
          {/* Videos */}
          <div className='flex flex-wrap w-full justify-center'>
            {videos.map((video, index) => (
              <div className='m-2 max-md:w-full' key={video.id || index}>
                <iframe src={video.url} className='max-md:h-[280px] w-[340px] h-[220px] max-md:w-full'></iframe>
                <div className='font-bold'>{video.title || 'Video Title'}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* References */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="bg-white h-fit py-4 w-full">
          <div className='text-[25px] text-center my-2 max-md:text-[20px] font-bold text-[#ffca05]'>References</div>
        </div>
      </div>
      {/* Questions and Answers */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="bg-white h-fit py-4 w-full">
          <div className='text-[25px] text-center my-2 max-md:text-[20px] font-bold text-[#ffca05]'>Questions and Answers</div>
        </div>
      </div>
    </div>
  );
};

const videos = [
  {
    id: 1,
    url: 'https://drive.google.com/file/d/1A6ZKOz4eoXmT_e4yPw099dnU_bEhXM53',
    title: 'Hadithi Fupi - Kisa cha Mbwa 1',
  },
  {
    id: 2,
    url: 'https://drive.google.com/file/d/1BLl8OzQ1VY6-_Dmz8Hh93aU685Sb_4d9/preview',
    title: 'Hadithi Fupi - Kisa cha Mbwa 2',
  },
  {
    id: 3,
    url: 'https://drive.google.com/file/d/19ybH0Xg2SJyZbFMjxIz7RDFeWJrfJFOT',
    title: 'Hadithi Fupi - Safari Dodoma',
  },
  // {
  //   id: 4,
  //   url: '',
  //   title: '',
  // },
  // {
  //   id: 5,
  //   url: '',
  //   title: '',
  // },
  // {
  //   id: 6,
  //   url: '',
  //   title: '',
  // },
  // {
  //   id: 7,
  //   url: '',
  //   title: '',
  // },
  // {
  //   id: 8,
  //   url: '',
  //   title: '',
  // },
  // {
  //   id: 9,
  //   url: '',
  //   title: '',
  // },
  // {
  //   id: 10,
  //   url: '',
  //   title: '',
  // },
  // {
  //   id: 11,
  //   url: '',
  //   title: '',
  // },
  // {
  //   id: 12,
  //   url: '',
  //   title: '',
  // },
  // {
  //   id: 13,
  //   url: '',
  //   title: '',
  // },
  // {
  //   id: 14,
  //   url: '',
  //   title: '',
  // },
  // {
  //   id: 15,
  //   url: '',
  //   title: '',
  // },
  // {
  //   id: 16,
  //   url: '',
  //   title: '',
  // },
  // {
  //   id: 17,
  //   url: '',
  //   title: '',
  // },
  // {
  //   id: 18,
  //   url: '',
  //   title: '',
  // },
  // {
  //   id: 19,
  //   url: '',
  //   title: '',
  // },
  // {
  //   id: 20,
  //   url: '',
  //   title: '',
  // },
]

export default Learn;
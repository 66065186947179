import React from 'react';
import { hrtmuzale, Logo, Picture1, Picture2, Picture3, Picture7 } from '../assets';
import { Link } from 'react-router-dom';

const AboutUs = () => {
  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="flex flex-col w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full h-fit max-md:px-[10px]">
        {/* Title */}
        <div className='flex m-2 text-[#31bfff] h-[80px] bg-white rounded-xl p-2 justify-center items-center text-center text-4xl max-md:text-3xl max-sm:text-2xl font-bold'>About Us</div>
      </div>
      {/* About LECO */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="flex flex-col gap-4 max-md:gap-2 max-md:flex-col my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full h-fit max-md:px-[10px]">
          <div className='flex max-md:flex-col w-full'>
            <div className="h-[300px] w-[540px] max-md:w-full flex justify-center items-center">
              <img src={Logo} className="h-full w-[300px] object-cover" />
            </div>
            <div className="flex flex-col h-fit py-2 w-[540px] max-md:w-full text-justify">
              <div>The Language, Education and Culture Organisation (LECO) is a non-profit organisation registered under the NGO Act No. 24 of 2002 in the Ministry of Health, Community Development, Gender, Elderly and Children, with Registration Number ……… . Envisaged in 2018, it exists mainly for the benefit of the general public and some specific sections of the public, especially minority, under-privileged and endangered species or communities. It is a crosscutting service provider that not only observes the two interrelated fields of language, as a component of culture and pillar for communication on the one hand, and education, as the pillar for socioeconomic development and self-actualization, but also those who missed training opportunities at some point in their lives.</div>
            </div>
          </div>
          <div className='flex max-md:flex-col w-full'>
            <div className='flex flex-col h-full w-[30%] max-md:w-full items-center'>
              <img src={hrtmuzale} className="bg-white w-[80%] max-md:w-[50%] object-cover" />
              <div className='text-center font-bold text-rose-700 mt-3'>HRT Muzale</div>
              <div className='text-center text-slate-700 font-medium text-sm'>Executive Director</div>
            </div>
            <div className="flex flex-col h-fit w-fit max-md:w-full text-justify">
              <div className='w-full text-justify text-purple-800'>Whether you are a researcher, educator, linguist, learner, trainer, documenter, consultant, culturalist, traditionalist, technologist, or just an interested stakeholder in any issue related to this organisation, LECO welcomes you to participate in its activities toward achievement of its goal and objectives. Given the number of languages spoken in Tanzania, the impending endangerment for local community languages, overwhelming challenges in language in education and communication skills, and the growing demand for the use of technology, especially artificial intelligence, in language and education, we cannot wait to see you jump on the bandwagon to save our community heritage for posterity.</div>
              <div className='w-full text-justify my-4 text-blue-500'>Our Motto is “<strong>Language is Knowledge and Heritage</strong>”.</div>
              <Link to={`/leadership`} className='w-[200px]'>
                <div className='font-bold text-green-500'>Leadership</div>
                <div className='text-slate-600 font-medium text-sm'>View all leaders of LECO</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Vision & Mission Statements */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="h-fit w-full">
          <div className='p-4'>
            <div className='font-bold text-emerald-500'>Vision Statement</div>
            <div className='text-justify text-emerald-900'>A reputable research, documentation and pedagogical organisation well-equipped with reliable, accessible and transmittable information on language teaching-learning, language function and culture that are relevant to education and development.</div>
          </div>
          <div className='p-4'>
            <div className='font-bold text-emerald-500'>Mission Statement</div>
            <div className='text-justify text-emerald-900'>To enhance the documentation, preservation and promotion of educative language and culture through research, training, publication and technology for effective heritage, communicative competence and community development.</div>
          </div>
        </div>
      </div>
      {/* Statement */}
      <div className='flex bg-white w-full justify-center'>
        <div className="flex flex-col gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
          <div className="h-fit w-full">
            <div className='text-[25px] text-center mb-2 max-md:text-[20px] font-bold text-[#ffca05]'>Statement on the languages of Tanzania</div>
            <div className='flex max-md:flex-col w-full justify-center items-center gap-4'>
              <img src={Picture7} className="w-[250px] h-[150px] object-cover" />
              <div className='text-justify'>Tanzania is a country rich in language and cultural diversity in the sense that many people speak three languages. The three-tier sociolinguistic situation distributes languages in three functional layers with distinctive statuses and attitudes associated with each layer and its embedded culture. The uppermost layer consists primarily of English, a tremendously powerful and prestigious official language, followed by the second layer that consists of Kiswahili, the national-cum-official language and the language of wider communication countrywide.</div>
            </div>
            <div className='text-justify my-2 font-medium text-emerald-800'>The uppermost layer consists primarily of English, a tremendously powerful and prestigious official language, followed by the second layer that consists of Kiswahili, the national-cum-official language and the language of wider communication countrywide.</div>
            <div className='flex max-md:flex-col w-full justify-center items-center gap-4'>
              <div className='text-justify mt-3'>The third layer consists of about 150 ethnic community languages that are largely neglected. The third layer includes varieties of signed languages which are the most marginalised languages in the country and their limitation subjects the Deaf and Deafblind communities to lack of social services and poor achievement in education, and thus their dire need for support and development cannot be overemphasised.</div>
              <img src={Picture1} className="w-[200px] h-auto object-cover" />
              <div className='text-justify mt-3'>This complex sociolinguistic situation poses a number of challenges to language acquisition/learning and use, language policy and development, language of instruction and communicative competence, as well as the role of language in development. The challenges tend to affect all the three linguistic layers, albeit in different ways, which can be summarised as follows.</div>
            </div>
            <div className='flex max-md:flex-col w-full justify-center items-center gap-4'>
              <div className='flex flex-col w-full justify-center items-center'>
                <div className='text-justify mt-3'>On the one hand, the ethnic community languages are a great cultural and knowledge resource for poverty alleviation and development strategies. However, their use is discouraged or prohibited in public affairs, and the system does not make provisions for official interpreters to serve those who are incompetent in the language used. It is noted with concern that there are no provisions for a Tanzanian child to learn to read and write his/her ethnic community language, other than Kiswahili, even though the power of the mother tongue in early learning has long been acknowledged internationally.</div>
                <img src={Picture2} className="w-[200px] md:hidden h-auto object-cover" />
                <div className='text-justify mt-3'>It is for these reasons that ethnic community languages need to be empowered in a socio-politically positive way that will enable them to play an effective role not only for Kiswahili development, but also in the national struggle against poverty and subsequently for sustainable national development. Research has shown that these languages form a unique reservoir of vocabulary, grammar, indigenous knowledge, cultural heritage, ethno-zoological and ethno-botanical richness that need tapping, documenting, emulating and transmitting across ethno-cultural boundaries and over generations.</div>
              </div>
              <img src={Picture2} className="w-[200px] max-md:hidden h-auto object-cover" />
            </div>
            <div className='text-justify my-2 font-medium text-teal-900'>Besides, while all languages are constantly changing, and some are on the verge of extinction, some people have been brainwashed to the extent of confusing community languages with tribalism and believing that only English deserves to be a language of instruction beyond primary education. That is why an intervention is highly needed.</div>
            <div className='text-justify mt-3'>On the other hand, the poor linguistic background that children receive at early stages of education, coupled with poor language teaching approaches/programmes, tends to affect the other two upper linguistic layers of Kiswahili and English. The result is an alarming language users’ incompetence in the language skills of listening, speaking, reading, writing and signing, as well as in other related professional skills, such as communication skills, translation, interpretation, report writing, creative writing and editing. This as well calls for professional attention.</div>
            <div className='flex mt-3 max-md:flex-col w-full justify-center items-center gap-4'>
              <img src={Picture3} className="w-[250px] h-auto object-cover" />
              <div className='text-justify'>It is this situation that calls for an intervention to address the challenges of the three interrelated components, namely language, education and culture, outside the formal education system. Intellectuals, professionals and interested stakeholders cannot sit back to just lament the demise of our heritage. It is time we seriously made it possible to document, preserve and harness the aspects of the sociolinguistic, sociocultural and communication systems of our communities. These include informal education, especially the unique features of every language community and personal linguistic potential that are relevant in communication, environment conceptualisation, socioeconomic systems, gender issues, health, poverty alleviation, wisdom, heroism and self-esteem, as well as enhance their contribution to development from family to national level. That is why LECO was envisaged.</div>
            </div>
          </div>
        </div>
      </div>
      {/* LECO Objectives */}
      <div className='flex bg-teal-900 text-white w-full justify-center'>
        <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
          <div className="h-fit py-4 w-full">
            <div className='text-[25px] text-center my-2 max-md:text-[20px] font-bold text-[#ffffff]'>LECO Objectives</div>
            <ol type="1" className='mx-7' style={{ listStyleType: 'decimal' }}>
              <li className='text-justify'>To promote and preserve the lingual-cultural diversity of the Tanzanian communities.</li>
              <li className='text-justify'>To enhance acquisition, exchange and transmission of indigenous knowledge.</li>
              <li className='text-justify'>To enhance the relevance and effectiveness of traditional informal sectors on the modern formal sectors through the power, influence and use of language and technology for development.</li>
              <li className='text-justify'>To promote and strengthen language documentation and cultural tourism, sociolinguistic and lingual-cultural appreciation and solidarity.</li>
              <li className='text-justify'>To promote effective literacy, advanced language skills and critical thinking in the three language tiers.</li>
              <li className='text-justify'>To enhance language users’ linguistic competence, linguistic performance, effective communication skills and professionalism.</li>
              <li className='text-justify'>To enhance acquisition/learning as well as the role and status of signed languages, such as Tanzanian Sign Language and Tanzanian Tactile Sign Language, to their full potential as languages of instruction in the education system and uniting tools for the Deaf and Deafblind communities, respectively.</li>
              <li className='text-justify'>To promote establishment and maintenance of ethno-linguistic, ethno-botanical and socio-cultural centres of research, consultancy, training, tutelage, cultural tourism, archives and nomenclature.</li>
              <li className='text-justify'>To promote creation of a pool of language experts, researchers, tutors, authors, writers, interpreters, translators, transcribers, orators, editors, recorders, guides and the like.</li>
              <li className='text-justify'>To promote coordination, facilitation and management of language and cultural programmes and produce relevant training materials and equipment.</li>
              <li className='text-justify'>To enhance efforts and strategies for compilation and publishing of references (namely books, dictionaries, lexicons, periodicals, encyclopaedia, wordlists, magazines and articles) on language, education and culture through relevant technology.</li>
              <li className='text-justify'>To enhance provision of support, services, guidance and advice to schools, institutions and the public on language in education, language and technology, terminology, language planning, and cultural development.</li>
              <li className='text-justify'>To propose and influence the development of policies and guidelines on language, education and culture.</li>
              <li className='text-justify'>To carry out all such other lawful activities that may further the Organisation’s mission.</li>
            </ol>
          </div>
        </div>
      </div>
      {/* Gallery */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="bg-white h-[320px] w-full">
          <div className='text-[25px] text-center my-2 max-md:text-[20px] font-bold text-[#ffca05]'>Gallery</div>

        </div>
      </div>
    </div>
  );
};

export default AboutUs;
import React, { useState, useEffect } from 'react';
import { BannerImage1, BannerImage2, BannerImage3, BannerImage4, BannerImage5 } from '../assets';

const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Add both local and URL images
  const images = [
    BannerImage1,
    BannerImage2,
    BannerImage3,
    BannerImage4,
    BannerImage5,
  ];

  // Function to go to the next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Function to go to the previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Auto-slide effect
  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // Change image every 5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative flex flex-col w-full h-fit overflow-hidden">
      {/* Images */}
      <img
        src={images[currentIndex]}
        alt={`Banner ${currentIndex + 1}`}
        className="h-[420px] max-md:h-[280px] w-full object-cover transition duration-500 ease-in-out"
      />
      <div className='w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full'>
        {/* Navigation Buttons */}
        <button
          onClick={prevSlide}
          className="absolute left-[10px] top-1/2 transform -translate-y-1/2 p-2 bg-white bg-opacity-50 rounded-full shadow-md hover:bg-opacity-75"
        >
          &#8249; {/* Left Arrow */}
        </button>
        <button
          onClick={nextSlide}
          className="absolute right-[10px] top-1/2 transform -translate-y-1/2 p-2 bg-white bg-opacity-50 rounded-full shadow-md hover:bg-opacity-75"
        >
          &#8250; {/* Right Arrow */}
        </button>
      </div>

      {/* Dots Navigation */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-white' : 'bg-gray-400'
              }`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
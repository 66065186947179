import React from 'react';
import { facebook, instagram, Logo, navlogo, twitter, youtube } from '../assets';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className="flex flex-col bg-[#0000003b] w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full h-full max-md:px-[10px]">
        <div className="flex max-md:flex-col h-fit p-1 gap-4 w-full justify-between">
          <div className='flex flex-col h-full w-[35%] my-3 max-md:w-full items-center max-md:items-start'>
            <img src={navlogo} className="w-[200px] h-[200px] object-cover" />
            <a
              className="text-white text-xl font-bold"
            >
              Social Pages
            </a>
            <div className="flex w-[232px] max-w-full flex-row items-start justify-between gap-5 mt-2 max-md:justify-start">
              <a href='https://facebook.com/leco'>
                <img
                  loading="fast"
                  src={facebook}
                  className="aspect-square object-cover object-center w-[30px] shrink-0 transition-transform duration-300 transform-gpu hover:scale-110"
                />
              </a>
              <a href='https://twitter.com/leco'>
                <img
                  loading="fast"
                  src={twitter}
                  className="aspect-square object-cover object-center w-[30px] shrink-0 transition-transform duration-300 transform-gpu hover:scale-110"
                />
              </a>
              <a href='https://instagram.com/leco'>
                <img
                  loading="fast"
                  src={instagram}
                  className="aspect-square object-cover object-center w-[30px] shrink-0 transition-transform duration-300 transform-gpu hover:scale-110"
                />
              </a>
              <a href='https://youtube.com/leco'>
                <img
                  loading="fast"
                  src={youtube}
                  className="aspect-[1.43] object-cover object-center w-[43px] shrink-0 transition-transform duration-300 transform-gpu hover:scale-110"
                />
              </a>
            </div>
          </div>
          <div className='h-full w-[35%] mt-[14px] max-md:w-full'>
            <div className='text-xl font-bold mb-3'>Our Contacts</div>
            <div className="text-white text-sm font-medium">
              <div className="font-bold mb-[15px]">
                Language, Education and Culture Organisation
              </div>
              <div className=''>House No. 2,</div>
              <div className=''>Eden Street,  Boko.</div>
              <div className=''>P. O. Box 35650,</div>
              <div className='mb-[14px]'>Dar es Salaam, Tanzania.</div>
              <div>
                <a href="tel:+255760115555 " className='hover:text-blue-500'>(+255) 760-115-555</a>
              </div>
              <div>
                <a href="tel:+255755007005" className='hover:text-blue-500'>(+255) 755-007-005</a>
              </div>
              <div>
                <a href='mailto:info@leco.or.tz' className='hover:text-blue-500'>info@leco.or.tz</a>
              </div>
            </div>
          </div>
          <div className='h-full w-[35%] mt-[14px] max-md:w-full'>
            <div className='text-xl font-bold'>Quick Links</div>
            <div className="text-white text-sm font-medium mt-3">
              <Link to={`/`} className='hover:text-blue-500'>
                <div className='my-1 hover:mx-1 transition-transform duration-300 transform-gpu hover:scale-110'>Home</div>
              </Link>
              <Link to={`/about-us`} className='hover:text-blue-500'>
                <div className='my-1 hover:mx-1 transition-transform duration-300 transform-gpu hover:scale-110'>About Us</div>
              </Link>
              <Link to={`/leadership`} className='hover:text-blue-500'>
                <div className='my-1 hover:mx-1 transition-transform duration-300 transform-gpu hover:scale-110'>Leadership</div>
              </Link>
              <Link to={`/membership`} className='hover:text-blue-500'>
                <div className='my-1 hover:mx-1 transition-transform duration-300 transform-gpu hover:scale-110'>Membership</div>
              </Link>
              <Link to={`/documentation`} className='hover:text-blue-500'>
                <div className='my-1 hover:mx-1 transition-transform duration-300 transform-gpu hover:scale-110'>Documentation</div>
              </Link>
              <Link to={`notices`} className='hover:text-blue-500'>
                <div className='my-1 hover:mx-1 transition-transform duration-300 transform-gpu hover:scale-110'>Notices</div>
              </Link>
              <Link to={`/learning`} className='hover:text-blue-500'>
                <div className='my-1 hover:mx-1 transition-transform duration-300 transform-gpu hover:scale-110'>Learning and Unlearning</div>
              </Link>
              <Link to={`/contact-us`} className='hover:text-blue-500'>
                <div className='my-1 hover:mx-1 transition-transform duration-300 transform-gpu hover:scale-110'>Contact Us</div>
              </Link>
            </div>
          </div>
        </div>
        <div className='flex max-md:flex-col w-full md:justify-between md:px-2 my-4'>
          <div className='hover:text-blue-600 cursor-pointer'>
            <a href={`/privacy-policy`}>Privacy Policy</a>
          </div>
          <div className='hover:text-blue-600 cursor-pointer max-md:mb-1'>
            <a href={`/terms-and-condition`}>Terms and conditions</a>
          </div>
          <div className='font-medium max-md:my-1'>Copyright © 2024 Leco. All rights reserved.</div>
        </div>
      </div>
    </>
  );
};

export default Footer;
import React, { useState } from 'react';
import FAQ from '../components/FAQ';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="flex flex-col w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full h-fit max-md:px-[10px]">
        {/* Title */}
        <div className='flex m-2 text-[#31bfff] h-[80px] bg-white rounded-xl p-2 justify-center items-center text-center text-4xl max-md:text-3xl max-sm:text-2xl font-bold'>Contact Us</div>
      </div>
      {/* Contact */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="bg-white flex flex-col w-full p-4">
          <div className='font-bold leading-[50px] text-4xl max-md:text-2xl w-[90%] max-md:w-full'>Reach out with</div>
          <div className='font-bold leading-[50px] text-4xl max-md:text-2xl w-[90%] max-md:w-full'>any questions you have</div>
          <div className='flex flex-col w-full gap-5'>
            <form className='flex flex-col w-full justify-center items-center'>
              <div className='flex max-md:flex-col w-full'>
                <input
                  type="name"
                  placeholder="Name"
                  required
                  className="bg-transparent w-[50%] max-md:w-full h-[60px] border border-gray-300 rounded-md px-4 mt-4 md:mb-2 md:mr-1"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="email"
                  placeholder="Email"
                  required
                  className="bg-transparent w-[50%] max-md:w-full h-[60px] border border-gray-300 rounded-md px-4 md:mt-4 max-md:mt-2 mb-2 md:ml-1"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <textarea
                type="message"
                placeholder="Message"
                required
                className="flex bg-transparent w-full max-md:w-full min-h-[200px] border border-gray-300 rounded-md p-4"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button className='flex cursor-pointer my-2 w-[188px] h-[50px] text-center rounded-[12px] border hover:bg-blue-500 bg-[#31bfff] p-2 text-white text-[12px] items-center justify-center'>Submit</button>
            </form>
          </div>
        </div>
      </div>
      {/* FAQ */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="bg-white h-fit p-4 w-full">
          <div className='text-[25px] text-center my-2 max-md:text-[20px] font-bold text-[#ffca05]'>FAQs</div>
          <FAQ />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;